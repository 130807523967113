import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Testimonies.css";
const testimonials = [
    {
      text: `Working with Apni has been a game-changer for our organization. Their penetration testing services provided us
             with critical insights into our vulnerabilities, allowing us to strengthen our defenses before any real threats
             emerged. The risk assessment process was thorough and eye-opening, identifying potential gaps we hadn't considered.
             Most importantly, their mitigation strategies were practical, effective, and tailored to our needs. We now operate
             with greater confidence, knowing our systems are secure and resilient. I highly recommend Apni Sec to any
             organization looking to safeguard their assets and data.`,
      author: "Moiz Arsiwala",
      title: "Co-founder, WorkIndia",
      image: "https://play-lh.googleusercontent.com/F1ojiMAAwyvoJ1eisXPvbWu6stVUFLnx21x-awuRr9apKyYTVx5hHvZS0XP_-jgXWk8",
    },
    {
      text: `We've been working with ApniSec for over a year, and they've completely changed the way we handle cybersecurity. From day one, they really took the time to understand our business and put together a security plan that fits our needs perfectly. Their tools—like threat detection and monitoring—make us feel confident that our data is safe and compliant with industry rules. Plus, their team is super responsive, easy to work with, and always goes the extra mile. We couldn't ask for a better partner to help keep us secure.`,
      author: "Praveen Kumar",
      title: "CTO, Livspace",
      image: "https://www.finsmes.com/wp-content/uploads/2016/08/livspace.jpg",
    },
    {
      text: `Apnisec is proactive, skilled, and vigilant, anticipating and mitigating risks to protect the organization. They communicate effectively and stay innovative in the ever-evolving security landscape. They handle incidents with precision and uphold ethical standards while ensuring compliance. They enable trust and resilience, helping safeguard sensitive data and maintain business continuity. Their dedication and expertise make them invaluable guardians of organizational safety.`,
      author: "Sourav Pathak",
      title: "Principal Engineer, OZiva",
      image: "https://play-lh.googleusercontent.com/F68vJhqcWZAXTagwqhWBZu_pdpaCV2XcwWJUPbSpSHnU_5ZFD6OHn3_Bn-MFlaWH5g-f",
    },
    {
        text: `Our partnership with ApniSec has been an absolute game-changer for Rang De’s IT security. Their proactive approach keeps us a step ahead of potential threats, ensuring that we stay vigilant and prepared. Their regular audits, mock drills are not just thorough but also educational—they help us understand our vulnerabilities and address them before they become issues. One of the most impactful aspects of their service is their ability to decode security breaches in other industries and translate those lessons into practical safeguards for us. We especially look forward to their regular awareness sessions for the team. With ApniSec, we have learnt that every team member is responsible for IT Security.`,
        author: "Smita Ram",
        title: "Founder, RangDe",
        image: "https://media.licdn.com/dms/image/v2/C560BAQHpfBktzte1_A/company-logo_200_200/company-logo_200_200/0/1643716539191/wearerangde_logo?e=2147483647&v=beta&t=DdNhsRzgjhLykLHx2DQZ0ib5J1tpTIl53Ea6dW7T8z0",
      },
  ];
export default function Testimonies({ activePage }) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const updateSlider = (index) => {
      setCurrentIndex(index);
    };
  
    // Auto-slide functionality
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 5000); // Change slide every 5 seconds
      return () => clearInterval(interval);
    }, []);
  
  const navigate = useNavigate();


  // Define a background color for each active page
  const backgroundColors = {
    home: "rgb(245, 255, 252)", // Light blue
    vciso: "rgb(245, 255, 252)", // Misty rose
    darkeye: "rgb(250, 250, 250)", // Lavender
    contactUs: "#0f1a1b", // Lemon chiffon
    cloudsecurity: "rgba(139, 240, 251, 0.1)",
    vapt: "rgba(84, 241, 149, 0.1)",
    redteam:  "rgba(255, 171, 171, 0.1)",
  };


  const bordercolor = {
    home: "rgb(0, 255, 178)", // Light blue
    vciso: "rgb(0, 255, 178)", // Misty rose
    darkeye: "rgb(202, 202, 202)", // Lavender
    cloudsecurity: "rgba(139, 240, 251)",
    vapt: "rgb(84, 241, 129)",
    redteam: "rgb(255, 171, 171)"
  };

  const testimonyStyle = {
    backgroundColor: backgroundColors[activePage] || "var(--primary)",
    borderBottom: "0.5px solid",
    borderColor: bordercolor[activePage] || "var(--primary)",
  };

  return (
    <div className="testimonial-slider">
    <div
      className="slider-wrapper"
      style={{ transform: `translateX(-${currentIndex * 100}%)` }}
    >
      {testimonials.map((testimonial, index) => (
        <div className="testimonial-card" key={index} style={testimonyStyle}>
          <p className="testimonial-text">{testimonial.text}</p>
          <div className="author-info">
            <img
              className="author-icon"
              src={testimonial.image}
              alt={`${testimonial.author}'s Icon`}
            />
            <div>
              <h4 className="author-name">{testimonial.author}</h4>
              <p className="author-title">{testimonial.title}</p>
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* Slider dots */}
    <div className="slider-dots">
      {testimonials.map((_, index) => (
        <span
          key={index}
          className={`dot ${currentIndex === index ? "active" : ""}`}
          onClick={() => updateSlider(index)}
        ></span>
      ))}
    </div>
  </div>

 );
}
