import React, { useState } from "react";
import brandLogo from "../pages/assests/brand-logo-dk.svg";
import startupIndia from "../pages/assests/startupIndia.png";
import iso from "../pages/assests/iso.svg";
import linkedin from "../pages/assests/linkedin.svg";
import twitter from "../pages/assests/twitter.svg";
import youtube from "../pages/assests/youtube.svg";
import line70 from "../pages/assests/line70.svg";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="frame-33">
        <div className="brand-logo-dk">
          <img src={brandLogo} style={{ width: "15vw" }} />
          <p style={{ fontSize: "1vw" }}>WE ARE CERTIFIED</p>
          <div>
            <img src={startupIndia} style={{ width: "10vw" }} />
            <img src={iso} style={{ width: "4vw" }} />
          </div>
          <div className="rights-reserved">
            © 2024 Apni Sec. All rights reserved.
          </div>
        </div>

        <div className="frame-34">
          <div className="flinks">
            <div className="frame-35">
              <div className="text-wrapper-45">Company</div>

              <div className="frame-36">
                <div
                  className="flink-options"
                  onClick={() => (window.location.href = "/")}
                >
                  Home
                </div>

                <div className="text-wrapper-32">Solutions</div>

                <div className="text-wrapper-32">Process</div>

                <div className="text-wrapper-32">Report</div>

                <div className="text-wrapper-32">Services</div>
              </div>
            </div>

            <div className="frame-35">
              <div className="text-wrapper-45">Services</div>

              <div className="frame-36">
                <div
                  className="flink-options"
                  onClick={() => (window.location.href = "/dark-eye-watcher")}
                >
                  Dark Eye Watcher
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => (window.location.href = "/cloud-security")}
                >
                  Cloud Security
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => (window.location.href = "/vciso")}
                >
                  Virtual CISO
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => (window.location.href = "/red-team-assesment")}
                >
                  Red Team Assesment
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => (window.location.href = "/vapt")}
                >
                  VAPT
                </div>
              </div>
            </div>
          </div>

          <div className="social-icon">
            <a
              href="https://www.linkedin.com/company/apnisec"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="cloudsecurity-socialIcon"
                alt="LinkedIn"
                src={linkedin}
              />
            </a>

            <img className="cloudsecurity-line-70" alt="Line" src={line70} />

            <a
              href="https://x.com/apnisec"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="cloudsecurity-socialIcon"
                alt="X (formerly Twitter)"
                src={twitter}
              />
            </a>

            <img className="cloudsecurity-line-70" alt="Line" src={line70} />

            <a
              href="https://www.youtube.com/@apnisec"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="cloudsecurity-socialIcon"
                alt="YouTube"
                src={youtube}
              />
            </a>
          </div>

          <div className="privacy-policy-terms">
            Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms And Services
          </div>
        </div>
      </div>
    </footer>
  );
}
