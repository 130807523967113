import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

export default function Navbar({ activePage }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us"); // Redirect to /contact-us
  };

  const handleLogoClick = () => {
    navigate("/"); // Redirect to home
  };

  // Define a background color for each active page
  const backgroundColors = {
    home: "#0f1a1b", // Light blue
    vciso: "#0F1A1B", // Misty rose
    darkeye: "#000", // Lavender
    contactUs: "#0f1a1b", // Lemon chiffon
    cloudsecurity: "#000808",
    vapt: "#0f1a1b",
    redteamassesment:  "#0f1a1b",
  };

  // Fallback to a default color if activePage is not defined
  const navbarStyle = {
    backgroundColor: backgroundColors[activePage] || "#ffffff", // Default: white
  };

  const color = {
    home: "var(--primary)", // Light blue
    vciso: "var(--primary)", // Misty rose
    darkeye: "#fff", // Lavender
    // contactUs: "#0f1a1b", // Lemon chiffon
    cloudsecurity: "#8BF0FB",
    vapt: "#54f181",
    redteamassesment: "#ffabab"
  };

  const linkStyle = {
    color: color[activePage] || "var(--primary)",
    borderBottom: "0.5px solid",
    borderColor: color[activePage] || "var(--primary)",
  };

  return (
    <div className="navbar2" style={navbarStyle}>
      <div className="brand-logo-lt" onClick={handleLogoClick} />

      <div className="navlinks">
        <div
          className={`links ${activePage === "home" ? "active" : ""}`}
          onClick={() => navigate("/")}
        >
          Home
        </div>

        <div
          style={activePage === "vciso" ? linkStyle : {}}
          className={`links ${activePage === "vciso" ? "active" : ""}`}
          onClick={() => navigate("/vciso")}
        >
          vCISO
        </div>

        <div
          style={activePage === "darkeye" ? linkStyle : {}}
          className={`links ${activePage === "darkeye" ? "active" : ""}`}
          onClick={() => navigate("/dark-eye-watcher")}
        >
          DarkEye Watcher
        </div>

        <div
          style={activePage === "cloudsecurity" ? linkStyle : {}}
          className={`links ${activePage === "cloudsecurity" ? "active" : ""}`}
          onClick={() => navigate("/cloud-security")}
        >
          Cloud Security
        </div>

        <div
          style={activePage === "vapt" ? linkStyle : {}}
          className={`links ${activePage === "vapt" ? "active" : ""}`}
          onClick={() => navigate("/vapt")}
        >
          VAPT
        </div>

        <div
         style={activePage === "redteamassesment" ? linkStyle : {}}
          className={`links ${
            activePage === "redteamassesment" ? "active" : ""
          }`}
          onClick={() => navigate("/red-team-assesment")}
        >
          RedTeam Assesment
        </div>
      </div>

      <div className="nav-btn2" onClick={handleClick}>
        <div
          className="text-wrapper-42"
        >
          Secure Now
        </div>
      </div>
    </div>
  );
}
