import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import DarkEye from "./pages/DarkEye";
import VCiso from "./pages/VCiso";
import ContactUs from "./pages/ContactUs";
import CloudSecurity from "./pages/CloudSecurity";
import Vapt from "./pages/Vapt";
import RedTeamAssesment from "./pages/RedTeamAssesment"

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/dark-eye-watcher" element={<DarkEye />} />
      <Route path="/vciso" element={<VCiso />} />
      <Route path="/cloud-security" element={<CloudSecurity />} />
      <Route path="/vapt" element={<Vapt />} />
      <Route path="/red-team-assesment" element={<RedTeamAssesment />} />
    </Routes>
  );
}

export default App;
