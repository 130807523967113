import React, { useState, useEffect } from "react";
import brandLogo from "./assests/brand-logo-dk.svg";
import linkedin from "./assests/linkedin.svg";
import twitter from "./assests/twitter.svg";
import youtube from "./assests/youtube.svg";
import line70 from "./assests/line70.svg";
import "./style.css";
import "../styleguide.css";
import "./contactUsStyle.css";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

export default function ContactUs() {
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    customerApps: 0,
    roleMatrix: false,
    dynamicEndpoints: 0,
    domain: "",
    subdomains: 0,
    cloudAccounts: 0,
    techTeamSize: 0,
    additionalInfo: "",
  });

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const location = useLocation();
  const { activePage } = location.state || "home"; // Extracting activePage from location state

  useEffect(() => {
    console.log(activePage);
    if (activePage === "darkeye") {
      setFormData((prevData) => ({
        ...prevData,
        domainCount: 5, // Pre-fill domain count for Dark Eye
      }));
    }
  }, [activePage]);

  const color = {
    home: "var(--primary)",
    vciso: "var(--primary)",
    darkeye: "#fff",
    contactUs: "#0f1a1b",
    cloudsecurity: "#8BF0FB",
    vapt: "#54f181",
    redteamassesment: "#ffabab",
  };

  const linkStyle = {
    backgroundColor: color[activePage] || "var(--primary)",
    borderBottom: "0.5px solid",
    borderColor: color[activePage] || "var(--primary)",
  };

  const data = {
    home: " Cyber Security Consultancy",
    vciso: "vCISO",
    darkeye: "Dark Eye Watcher",
    cloudsecurity: "Cloud Security",
    vapt: "VAPT",
    redteamassesment: "Red Team Assesment",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submitting");
    console.log(formData);

    // Prepare the payload
    const payload = {
      firstName: formData.firstName,
      lastName: formData.name,
      email: formData.email,
      message: formData.additionalInfo || "No additional information provided",
      dynamicEndpoint: formData.dynamicEndpoints,
      customerFacingApplications: formData.customerApps,
      domainsOwned: formData.domain,
      subdomainsCount: formData.subdomains,
      sizeOrg: formData.organizationSize,
      sizeTech: formData.techTeamSize,
      cloudAccountsCount: formData.cloudAccounts,
      organizationName: "Apni Sec",
      serviceName: activePage || "home",
    };

    try {
      const response = await fetch("https://api.apnisec.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
      } else {
        console.error("Error submitting the form", response.statusText);
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  const renderFields = () => {
    switch (activePage) {
      case "home":
        return (
          <>
            <div class="form-group">
              <label for="first-name">Full Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>

            <div class="form-group">
              <label for="customer-apps">
                Number of Customer-Facing Applications (Web + Mobile)
              </label>
              <input
                type="number"
                id="customer-apps"
                min="0"
                value={formData.customerApps}
                onChange={(e) =>
                  setFormData({ ...formData, customerApps: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Do you have a Role Matrix across applications?</label>
              <div className="toggle-group">
                <input
                  type="checkbox"
                  id="role-matrix-toggle"
                  className="toggle-input"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <label htmlFor="role-matrix-toggle" className="toggle-label">
                  <span className="toggle-text">
                    {isChecked ? "Yes" : "No"}
                  </span>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label for="dynamic-endpoints">
                Estimated Number of Dynamic Endpoints
              </label>
              <input
                type="number"
                id="dynamic-endpoints"
                min="0"
                value={formData.dynamicEndpoint}
                onChange={(e) =>
                  setFormData({ ...formData, dynamicEndpoint: e.target.value })
                }
              />
            </div>

            <div class="form-group">
              <label for="domain">Domain Owned by the Organization</label>
              <input type="text" id="domain" placeholder="Enter your domain" />
            </div>

            <div class="form-group">
              <label for="subdomains">Estimated Total Subdomains Count</label>
              <input type="number" id="subdomains" min="0" />
            </div>

            <div class="form-group">
              <label for="cloud-accounts">Number of Cloud Accounts</label>
              <input type="number" id="cloud-accounts" min="0" />
            </div>

            <div class="form-group">
              <label for="organization-size">Size of the Organization</label>
              <select id="organization-size">
                <option value="small">Small (1-50 employees)</option>
                <option value="medium">Medium (51-200 employees)</option>
                <option value="large">Large (201+ employees)</option>
              </select>
            </div>

            <div class="form-group">
              <label for="tech-team-size">Size of the Tech Team</label>
              <input type="number" id="tech-team-size" value="0" min="0" />
            </div>

            <div class="form-group">
              <label for="additional-info">
                If there's anything else you'd like to share about your
                cybersecurity goals or concerns, please let us know:
              </label>
              <textarea
                id="additional-info"
                rows="4"
                placeholder="Write your message here"
              ></textarea>
            </div>
          </>
        );

      case "darkeye":
        return (
          <>
            <div className="form-group">
              <label htmlFor="first-name">Full Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="domain">Domain Owned by the Organization</label>
              <input
                type="text"
                id="domain"
                placeholder="Enter your domain"
                value={formData.domain}
                onChange={(e) =>
                  setFormData({ ...formData, domain: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="domain-count">Domain Count</label>
              <input
                type="number"
                id="domain-count"
                value={formData.domainCount}
                onChange={(e) =>
                  setFormData({ ...formData, domainCount: e.target.value })
                }
                readOnly
              />
            </div>
          </>
        );

      case "vciso":
        return (
          <>
            <div className="form-group">
              <label htmlFor="first-name">Full Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                id="organization"
                placeholder="Enter your organization"
                value={formData.organization}
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-apps">
                Customer Facing Applications
              </label>
              <input
                type="number"
                id="customer-apps"
                value={formData.customerApps}
                onChange={(e) =>
                  setFormData({ ...formData, customerApps: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="organization-size">
                Size of the Organization
              </label>
              <select
                id="organization-size"
                value={formData.organizationSize}
                onChange={(e) =>
                  setFormData({ ...formData, organizationSize: e.target.value })
                }
              >
                <option value="small">Small (1-50 employees)</option>
                <option value="medium">Medium (51-200 employees)</option>
                <option value="large">Large (201+ employees)</option>
              </select>
            </div>
          </>
        );

      case "redteamassesment":
        return (
          <>
            <div className="form-group">
              <label htmlFor="first-name">Full Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                id="organization"
                placeholder="Enter your organization"
                value={formData.organization}
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-apps">
                Number of Customer Facing Applications
              </label>
              <input
                type="number"
                id="customer-apps"
                value={formData.customerApps}
                onChange={(e) =>
                  setFormData({ ...formData, customerApps: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="domain">Domain Owned by Organization</label>
              <input
                type="text"
                id="domain"
                placeholder="Enter your domain"
                value={formData.domain}
                onChange={(e) =>
                  setFormData({ ...formData, domain: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="organization-size">
                Size of the Organization
              </label>
              <select
                id="organization-size"
                value={formData.organizationSize}
                onChange={(e) =>
                  setFormData({ ...formData, organizationSize: e.target.value })
                }
              >
                <option value="small">Small (1-50 employees)</option>
                <option value="medium">Medium (51-200 employees)</option>
                <option value="large">Large (201+ employees)</option>
              </select>
            </div>
          </>
        );

      case "vapt":
        return (
          <>
            <div className="form-group">
              <label htmlFor="first-name">Full Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                id="organization"
                placeholder="Enter your organization"
                value={formData.organization}
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-apps">
                Customer Facing Applications
              </label>
              <input
                type="number"
                id="customer-apps"
                value={formData.customerApps}
                onChange={(e) =>
                  setFormData({ ...formData, customerApps: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="role-matrix-toggle">
                Do you have a Role Matrix across applications?
              </label>
              <input
                type="checkbox"
                id="role-matrix-toggle"
                checked={isChecked}
                onChange={handleToggle}
              />
            </div>
          </>
        );

      case "cloudsecurity":
        return (
          <>
            <div className="form-group">
              <label htmlFor="first-name">Full Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                id="organization"
                placeholder="Enter your organization"
                value={formData.organization}
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="cloud-accounts">Number of Cloud Accounts</label>
              <input
                type="number"
                id="cloud-accounts"
                value={formData.cloudAccounts}
                onChange={(e) =>
                  setFormData({ ...formData, cloudAccounts: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-apps">Number of Applications</label>
              <input
                type="number"
                id="customer-apps"
                value={formData.customerApps}
                onChange={(e) =>
                  setFormData({ ...formData, customerApps: e.target.value })
                }
              />
            </div>
          </>
        );

      default:
        return (
          <>
            <div class="form-group">
              <label for="first-name">Full Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>

            <div class="form-group">
              <label for="customer-apps">
                Number of Customer-Facing Applications (Web + Mobile)
              </label>
              <input
                type="number"
                id="customer-apps"
                min="0"
                value={formData.customerApps}
                onChange={(e) =>
                  setFormData({ ...formData, customerApps: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Do you have a Role Matrix across applications?</label>
              <div className="toggle-group">
                <input
                  type="checkbox"
                  id="role-matrix-toggle"
                  className="toggle-input"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <label htmlFor="role-matrix-toggle" className="toggle-label">
                  <span className="toggle-text">
                    {isChecked ? "Yes" : "No"}
                  </span>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label for="dynamic-endpoints">
                Estimated Number of Dynamic Endpoints
              </label>
              <input
                type="number"
                id="dynamic-endpoints"
                min="0"
                value={formData.dynamicEndpoint}
                onChange={(e) =>
                  setFormData({ ...formData, dynamicEndpoint: e.target.value })
                }
              />
            </div>

            <div class="form-group">
              <label for="domain">Domain Owned by the Organization</label>
              <input type="text" id="domain" placeholder="Enter your domain" />
            </div>

            <div class="form-group">
              <label for="subdomains">Estimated Total Subdomains Count</label>
              <input type="number" id="subdomains" min="0" />
            </div>

            <div class="form-group">
              <label for="cloud-accounts">Number of Cloud Accounts</label>
              <input type="number" id="cloud-accounts" min="0" />
            </div>

            <div class="form-group">
              <label for="organization-size">Size of the Organization</label>
              <select id="organization-size">
                <option value="small">Small (1-50 employees)</option>
                <option value="medium">Medium (51-200 employees)</option>
                <option value="large">Large (201+ employees)</option>
              </select>
            </div>

            <div class="form-group">
              <label for="tech-team-size">Size of the Tech Team</label>
              <input type="number" id="tech-team-size" value="0" min="0" />
            </div>

            <div class="form-group">
              <label for="additional-info">
                If there's anything else you'd like to share about your
                cybersecurity goals or concerns, please let us know:
              </label>
              <textarea
                id="additional-info"
                rows="4"
                placeholder="Write your message here"
              ></textarea>
            </div>
          </>
        );
    }
  };

  return (
    <div className="website-layer">
      <div className="website-v-layer">
        <div className="main-wrapper">
          <Navbar activePage="contactUs" />
          <div className="contactus-main-section">
            <div className="left-section">
              <h1>Welcome To Apni Sec</h1>
              <p style={{ color: "white" }}>
                We're Here To Help Secure Your Business. If You're Unsure Which
                Services Suit Your Needs, Select Discovery Call Below, And Our
                Experts Will Guide You.
              </p>
              <button
                className="cta-button"
                style={{ backgroundColor: color[activePage] }}
              >
                1:1 on {data[activePage]}
              </button>
            </div>
            <div className="right-section">
              <form className="form">
                {renderFields()}
                <button
                  className="submit-button"
                  style={linkStyle}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
