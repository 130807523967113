import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg";
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png";
import tushar from "./assests/tushar.png";
import rajat from "./assests/rajat.jpg";
import shield from "./assests/shield.svg";
import networkLines from "./assests/networkLines.svg";
import brandLogo from "./assests/brand-logo-dk.svg";
import certified from "./assests/certified.svg";
import linkedin from "./assests/linkedin.svg";
import twitter from "./assests/twitter.svg";
import youtube from "./assests/youtube.svg";
import heroBrands from "./assests/heroBrands.svg";
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg";
import reportImg from "./assests/report-img.png";
import reportImgSingle from "./assests/report-img-single.png";
import "./style.css";
import "../styleguide.css";
import vector from "./assests/vector.svg";
import apple from "./assests/appreciationLogos/apple.svg";
import google from "./assests/appreciationLogos/google.svg";
import amazon from "./assests/appreciationLogos/amazon.svg";
import microsoft from "./assests/appreciationLogos/microsoft.svg";
import asus from "./assests/appreciationLogos/asus.svg";
import redbull from "./assests/appreciationLogos/redbull.svg";
import dell from "./assests/appreciationLogos/dell.svg";
import dominos from "./assests/appreciationLogos/dominos.svg";
import figma from "./assests/appreciationLogos/figma.svg";
import flipkart from "./assests/appreciationLogos/flipkart.svg";
import atlassian from "./assests/appreciationLogos/atlassian.svg";
import jira from "./assests/appreciationLogos/jira.svg";
import licious from "./assests/appreciationLogos/licious.svg";
import facebook from "./assests/appreciationLogos/facebook.svg";
import mastercard from "./assests/appreciationLogos/mastercard.svg";
import ncrb from "./assests/appreciationLogos/ncrb.svg";
import ncipc from "./assests/appreciationLogos/ncipc.svg";
import yahoo from "./assests/appreciationLogos/yahoo.svg";
import zomato from "./assests/appreciationLogos/zomato.svg";
import paytm from "./assests/appreciationLogos/paytm.svg";
import sony from "./assests/appreciationLogos/sony.svg";
import fileSearchIcon from "./assests/fileSearchIcon.svg";
import crosshairIcon from "./assests/crosshairIcon.svg";
import eyelineIcon from "./assests/eyelineIcon.svg";
import shieldIcon from "./assests/shieldIcon.svg";
import fileChartIcon from "./assests/fileChartIcon.svg";
import eyeline from "./assests/eyeline.svg";
import shieldGreenIcon from "./assests/shieldGreenIcon.svg";
import rupeeIcon from "./assests/rupeeIcon.svg";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Testimonies from "../components/Testimonies";
import howWeDoIt from "./assests/howWeDoIt.svg";
import numbersDontLie from "./assests/numbersDontLie.png";

const testimonials = [
  {
    text: `Working with Apni has been a game-changer for our organization. Their penetration testing services provided us
           with critical insights into our vulnerabilities, allowing us to strengthen our defenses before any real threats
           emerged. The risk assessment process was thorough and eye-opening, identifying potential gaps we hadn't considered.
           Most importantly, their mitigation strategies were practical, effective, and tailored to our needs. We now operate
           with greater confidence, knowing our systems are secure and resilient. I highly recommend Apni Sec to any
           organization looking to safeguard their assets and data.`,
    author: "Moiz Arsiwala",
    title: "Co-founder, WorkIndia",
    image: "path/to/database-icon.png", // Replace with the actual path
  },
  {
    text: `Another testimonial goes here. Customize as needed for additional testimonials.`,
    author: "John Doe",
    title: "Founder, Example",
    image: "path/to/database-icon.png",
  },
  {
    text: `A third testimonial example for your slider.`,
    author: "Jane Smith",
    title: "CTO, Startup",
    image: "path/to/database-icon.png",
  },
];

export default function Home() {
  const faqData = [
    {
      question: "How often are alerts generated?",
      answer:
        "Alerts are generated based on specific conditions or triggers, which can vary depending on the system.",
    },
    {
      question: "What is the average response time?",
      answer:
        "The average response time is typically under 5 minutes, depending on the complexity of the issue.",
    },
    {
      question: "Can I customize the alerts?",
      answer:
        "Yes, you can customize alerts based on your preferences and system configurations.",
    },
    {
      question: "What platforms are supported?",
      answer:
        "Our service supports Windows, macOS, Linux, and mobile platforms like iOS and Android.",
    },
    {
      question: "Is there a trial period available?",
      answer: "Yes, we offer a 14-day free trial with access to all features.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us"); // Redirect to /contact-us
  };

  const handleLogoClick = () => {
    navigate("/"); // Redirect to /contact-us
  };

  return (
    <div className="website-layer">
      <div className="website-v-layer">
        <div className="main-wrapper">
          <Navbar activePage="home" />

          <div className="hero-section">
            <div className="hero-section-2">
              <div className="hero-header">
                <p className="defend-against-cyber">
                  <span className="span">Defend Against </span>

                  <span className="text-wrapper-5">Cyber Threats</span>

                  <span className="span"> Before They Strike</span>
                </p>

                <p className="p">
                  Elevate your security posture with advanced security solutions
                  and in-depth vulnerability assessments, aligned with the
                  trusted frameworks of OWASP, NIST, SANS, CERT, and NIC.
                </p>
              </div>

              <div>
                <div className="download-btn">
                  <div className="text-wrapper-4" onClick={() =>
                        window.open(
                          "https://assets.apnisec.com/public/Sample+Reports.zip",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}>Download Free Report</div>
                </div>
                <div
                  className="div-wrapper"
                  onClick={() =>
                    navigate("/contact-us", {
                      state: { activePage: "home" }, // Passing 'darkeye' as the value for activePage
                    })
                  }
                >
                  <div className="text-wrapper-6">Get Quote</div>
                </div>
              </div>

              <div className="trust">
                <div className="overall-rating">
                  <div className="frame" style={{display: "flex", flexDirection: "column", gap: "0px"}}>
                    <img
                      className="img"
                      alt="overallRating"
                      src={overallRating}
                    />
                    <p style={{fontSize: "1vw", color: "white", margin: "0px", fontWeight: "600"}}>We secure 1000+ Organizations</p>
                  </div>
                </div>

                <div className="frame-2">
                  <div className="image-slider">
                    <img className="img-2" alt="Img" src={asus} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={amazon} />
                    <img className="img-2" alt="Img" src={redbull} />
                    <img className="img-2" alt="Img" src={dell} />
                    <img className="img-2" alt="Img" src={apple} />
                    <img className="img-2" alt="Img" src={dominos} />
                    <img className="img-2" alt="Img" src={flipkart} />
                    <img className="img-2" alt="Img" src={microsoft} />
                    <img className="img-2" alt="Img" src={atlassian} />
                    <img className="img-2" alt="Img" src={figma} />
                    <img className="img-2" alt="Img" src={jira} />
                    <img className="img-2" alt="Img" src={licious} />
                    <img className="img-2" alt="Img" src={facebook} />
                    <img className="img-2" alt="Img" src={mastercard} />
                    <img className="img-2" alt="Img" src={ncrb} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={ncipc} />
                    <img className="img-2" alt="Img" src={yahoo} />
                    <img className="img-2" alt="Img" src={zomato} />
                    <img className="img-2" alt="Img" src={paytm} />
                    <img className="img-2" alt="Img" src={sony} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="services-section">
            <div className="div-2">
              <button
                className="primary-btn-instance"
                divClassName="design-component-instance-node"
                property1="tag"
              >
                Services
              </button>
              <div className="title">
                <div className="text-wrapper-8">
                  Comprehensive <br />
                  cybersecurity{" "}
                  <span style={{ position: "relative", zIndex: "10" }}>
                    Solutions
                  </span>
                  <span>
                    {" "}
                    <img className="line" alt="Line" src={line69} />
                  </span>
                </div>
              </div>
            </div>

            <div className="services-card">
              {/* <div className="small-cards"> */}
              <div className="wrapper">
                <div
                  className="card"
                  onClick={() => (window.location.href = "/dark-eye-watcher")}
                >
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="cardHeader">Dark Eye Watcher</div>
                    </div>

                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <p className="cardPointer">
                          Monitor the dark web for compromised data.
                        </p>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Tracking Data Breaches 24x7
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Threat Intelligence Platform
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Data Loss Prevention (DLP)
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Brand Protection Services
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card-1"
                  onClick={() => (window.location.href = "/cloud-security")}
                >
                  <div className="frame-3">
                    <div className="frame-7">
                      <div className="cardHeader">Cloud Security</div>
                    </div>

                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">Asset Monitoring</div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Cloud Security Posture Management
                        </div>
                      </div>

                      <div className="frame-8">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Microservices Security
                        </div>
                      </div>

                      <div className="frame-8">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Cloud Attack Emulation
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card-2"
                  onClick={() => (window.location.href = "/red-team-assesment")}
                >
                  <div className="frame-3">
                    <div className="frame-4">
                      <div className="cardHeader">Red Team Assessment</div>
                    </div>

                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Social Engineering Simulation Campaigns & Evaluation
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Assess Vulnerabilities in System and Processes
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          On-site Network Firewall and Process audits
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card-3"
                  onClick={() => (window.location.href = "/vapt")}
                >
                  <div className="frame-3">
                    <div className="frame-7">
                      <div className="cardHeader">End-to-End VAPT</div>
                    </div>

                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-wrapper">
                              <div className="overlap-group-3">
                                <div className="ellipse" />

                                <img
                                  className="vector"
                                  alt="Vector"
                                  src={vector}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <p className="cardPointer">
                          Web, API & Mobile Application Security
                        </p>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">Secure Code Review</div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <p className="cardPointer">
                          Vulnerability Assessment &amp; Penetration Testing
                        </p>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">Network Security</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div
                className="big-card"
                onClick={() => (window.location.href = "/vciso")}
              >
                <div className="card-big">
                  <div className="frame-3">
                    <div className="frame-7">
                      <div className="cardHeader">Virtual CISO</div>
                    </div>

                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-wrapper">
                              <div className="overlap-group-3">
                                <div className="ellipse" />

                                <img
                                  className="vector"
                                  alt="Vector"
                                  src={vector}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <p className="cardPointer">
                          Continuous Vulnerability Scanning & Asset monitoring
                        </p>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Auditing Weekly feature releases
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <p className="cardPointer">
                          DevSecOps - Shift Left Culture
                        </p>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Zero Trust Security Model
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">Threat Modelling</div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Social Engineering Simulations & Awareness Trainings
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Information Security Policy & Cyber risk Maturity plan
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Secure Architecture Review
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">Bug Bounty Program</div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Vendor Monitoring System
                        </div>
                      </div>

                      <div className="frame-6">
                        <div className="group-wrapper">
                          <div className="group-2">
                            <div className="overlap-group-3">
                              <div className="ellipse" />

                              <img
                                className="vector"
                                alt="Vector"
                                src={vector}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cardPointer">
                          Compliance as a service
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="report-section">
            <div className="report-component">
              <div className="section-left">
                {" "}
                <img className="report-img" alt="Report img" src={reportImg} />
                <div className="content-left">
                  <div className="text-wrapper-13">Sample Reports</div>

                  <p className="comprehensive">
                    Comprehensive Test cases & Reports to identify Loopholes and
                    provide actionable insights for risk mitigation.
                  </p>
                </div>
                <div className="section-div-wrapper">
                  <div className="text-wrapper-section" onClick={() =>
                        window.open(
                          "https://assets.apnisec.com/public/Sample+Reports.zip",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}>
                    Download Sample Report
                  </div>
                </div>
              </div>
              <div className="section-right">
                <img
                  className="report-img-single"
                  alt="Report img"
                  src={reportImgSingle}
                />
                <div className="content-right">
                  <div className="text-wrapper-131">
                    Customized Sample Report
                  </div>

                  <p className="comprehensive">
                    Comprehensive Test cases & Reports to identify Loopholes and
                    provide actionable insights for risk mitigation.
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "rgb(245, 255, 252, 0.05)",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Type Your Domain"
                    style={{
                      padding: "10px 15px",
                      fontSize: "16px",
                      color: "#e0e0e0",
                      backgroundColor: "transparent",
                      border: "none",
                      borderRadius: "5px",
                      outline: "none",
                      width: "300px",
                    }}
                  />
                  <button
                    style={{
                      padding: "1vw 2vw",
                      fontSize: "1vw",
                      color: "#000",
                      backgroundColor: "#00ffb2",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "rgb(0, 255, 178, 0.9)")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#00ffb2")
                    }
                  >
                    Get Your Dark Web Report
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="stats-section">
            <div className="div-3">
              <div className="header">
                <img src={numbersDontLie} style={{ width: "35vw" }} />
                <div className="frame-11">
                  <div className="text-wrapper-14">849M+</div>

                  <div className="lines-of-code">Lines Of Code Reviewed</div>
                </div>
              </div>

              <div className="frame-12"></div>
            </div>

            <div className="div-3">
              <div className="frame-15">
                <div className="text-wrapper-14">3Bn+</div>

                <div className="text-wrapper-30">Records Scraped</div>
              </div>

              <div className="frame-15">
                <div className="element">15K+</div>

                <div className="text-wrapper-30">Assets Monitored</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-14">200TB+</div>

                <div className="text-wrapper-30">Data Analysed</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-14">99.99%</div>

                <div className="text-wrapper-30">Threat Mitigation</div>
              </div>
            </div>
          </div>

          <div className="how-we-do-it-section">
            <img src={howWeDoIt} />
          </div>

          <div className="about-section">
            <div className="about-component">
              <div className="col">
                <div className="div-5">
                  <div className="text-wrapper-34">Our Mission</div>

                  <p className="comprehensive">
                    To make Digital Space A safer Place to be
                  </p>

                  <div className="frame-18">
                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          Persistent surveillance
                        </div>
                      </div>
                    </div>

                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          Brand reputation Security
                        </div>
                      </div>
                    </div>

                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          InHouse security Team
                        </div>
                      </div>
                    </div>

                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          Proactive instead of Reactive
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="our-expert-team-of">
                  Our Expert Team <br />
                  of Hacker
                </p>
              </div>

              <div className="col-2">
                <div className="div-4">
                  <div className="text-wrapper-36">Why Choose Us</div>

                  <div className="USP">
                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={databaseIcon}
                      />

                      <div className="text-wrapper-37">Data Loss Prevented</div>
                    </div>

                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={eyeline}
                      />

                      <div className="text-wrapper-37">
                        Third Party Monitoring{" "}
                      </div>
                    </div>

                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={shieldGreenIcon}
                      />

                      <div className="text-wrapper-37">Compliance Related</div>
                    </div>

                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={rupeeIcon}
                      />

                      <div className="text-wrapper-37">
                        Financial Loss Prevented
                      </div>
                    </div>
                  </div>
                </div>

                <div className="certificates">
                  <img
                    className="certificateIcon"
                    alt="Screenshot"
                    src={certificates}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="our-mission">
            <div className="about-mission-component">
              <div className="col">
                <div className="div-5">
                  <img className="shieldIcon" alt="shieldIcon" src={shield} />
                </div>
                <div>
                  <p className="our-secured-brands">
                    Our Secured
                    <br />
                    Brands
                  </p>
                  <p className="comprehensive-2">
                    Retained 100% Customers since{" "}
                    <span style={{ fontWeight: "600" }}>inception</span>.
                  </p>
                </div>
              </div>

              <div className="col-2">
                <div className="certificates">
                  <img
                    className="trustedBrands"
                    alt="Screenshot"
                    src={trustedBrands}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="title-4">
            <div className="title">
              <div className="text-wrapper-8">
                Our secured{" "}
                <span
                  style={{
                    position: "relative",
                    zIndex: "10",
                    textTransform: "capitalize",
                  }}
                >
                  Client’s says it all
                </span>
                <span>
                  {" "}
                  <img className="line-13" alt="Line" src={line69} />
                </span>
              </div>
            </div>
          </div>

          <Testimonies activePage="home" />

          <div className="team-section">
            <div className="text-wrapper-39">Meet Our Team</div>

            <div className="cards">
              <div className="card-4">
                <img className="image" alt="Image" src={hardik} />

                <div className="frame-30">
                  <div className="frame-31">
                    <div className="text-wrapper-11">Hardik Rathod</div>

                    <div className="text-wrapper-38">Security Engineer</div>
                  </div>
                </div>
              </div>

              <div className="card-4">
                <img className="image" alt="Image" src={rajat} />

                <div className="frame-32">
                  <div className="frame-31">
                    <div className="text-wrapper-11">Rajat Moury</div>

                    <div className="text-wrapper-38">Founder</div>
                  </div>
                </div>
              </div>

              <div className="card-4">
                <img className="image" alt="Image" src={tushar} />

                <div className="frame-32">
                  <div className="frame-31">
                    <div className="text-wrapper-11">Tushar Kumar</div>

                    <div className="text-wrapper-38">Security Researcher</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="team-section">
            <div className="text-wrapper-39">Frequently Asked Questions</div>

            <div
              style={{
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                width: "65vw",
              }}
            >
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #00ffb2",
                    borderRadius: "5px",
                    marginBottom: "1vw",
                    backgroundColor: "#f5fffc",
                    padding: "1vw",
                  }}
                >
                  <div
                    onClick={() => toggleFAQ(index)}
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1vw",
                      cursor: "pointer",
                      fontSize: "1.25vw",
                    }}
                  >
                    {faq.question}
                    <span
                      style={{
                        transform:
                          activeIndex === index
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      ▼
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        padding: "2vw",
                        fontSize: "1vw",
                        color: "#333",
                        borderTop: "1px solid #c9f9c9",
                      }}
                    >
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="CTA-section">
            <div className="networkLinesWrapper">
              {" "}
              <img className="networkLines" alt="Vector" src={networkLines} />
            </div>

            <div className="div-2">
              <div className="text-wrapper-40">Protect Your Data Now</div>
            </div>
            <div
              className="div-wrapper"
              onClick={() =>
                navigate("/contact-us", {
                  state: { activePage: "home" }, // Passing 'darkeye' as the value for activePage
                })
              }
            >
              <div className="text-wrapper-6">Get Quote</div>
            </div>
            <div className="networkLinesWrapper1">
              {" "}
              <img className="networkLines1" alt="Vector" src={networkLines} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
