import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg";
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png";
import tushar from "./assests/tushar.png";
import rajat from "./assests/rajat.jpg";
import shield from "./assests/shield.svg";
import networkLines from "./assests/networkLines.svg";
import brandLogo from "./assests/brand-logo-dk.svg";
import certified from "./assests/certified.svg";
import linkedin from "./assests/linkedin.svg";
import twitter from "./assests/twitter.svg";
import youtube from "./assests/youtube.svg";
import heroBrands from "./assests/heroBrands.svg";
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg";
import reportImg from "./assests/report-img.png";
import reportImgSingle from "./assests/report-img-single.png";
import "../styleguide.css";
import vector from "./assests/vector.svg";
import apple from "./assests/appreciationLogos/apple.svg";
import google from "./assests/appreciationLogos/google.svg";
import amazon from "./assests/appreciationLogos/amazon.svg";
import microsoft from "./assests/appreciationLogos/microsoft.svg";
import asus from "./assests/appreciationLogos/asus.svg";
import redbull from "./assests/appreciationLogos/redbull.svg";
import dell from "./assests/appreciationLogos/dell.svg";
import dominos from "./assests/appreciationLogos/dominos.svg";
import figma from "./assests/appreciationLogos/figma.svg";
import flipkart from "./assests/appreciationLogos/flipkart.svg";
import atlassian from "./assests/appreciationLogos/atlassian.svg";
import jira from "./assests/appreciationLogos/jira.svg";
import licious from "./assests/appreciationLogos/licious.svg";
import facebook from "./assests/appreciationLogos/facebook.svg";
import mastercard from "./assests/appreciationLogos/mastercard.svg";
import ncrb from "./assests/appreciationLogos/ncrb.svg";
import ncipc from "./assests/appreciationLogos/ncipc.svg";
import yahoo from "./assests/appreciationLogos/yahoo.svg";
import zomato from "./assests/appreciationLogos/zomato.svg";
import paytm from "./assests/appreciationLogos/paytm.svg";
import sony from "./assests/appreciationLogos/sony.svg";
import fileSearchIcon from "./assests/fileSearchIcon.svg";
import crosshairIcon from "./assests/crosshairIcon.svg";
import eyelineIcon from "./assests/eyelineIcon.svg";
import shieldIcon from "./assests/shieldIcon.svg";
import fileChartIcon from "./assests/fileChartIcon.svg";
import eyeline from "./assests/eyeline.svg";
import shieldGreenIcon from "./assests/shieldGreenIcon.svg";
import rupeeIcon from "./assests/rupeeIcon.svg";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import "./VCisoStyle.css";
import "../styleguide.css";
import watcherImg from "./assests/WatcherDashboard.svg";
import darkeyeSnap from "./assests/darkeyeSnap.svg";
import dbIcon from "./assests/darkEyeIcons/dbIcon.svg";
import fileIcon from "./assests/darkEyeIcons/fileIcon.svg";
import nodeIcon from "./assests/darkEyeIcons/nodeIcon.svg";
import notifyIcon from "./assests/darkEyeIcons/notifyIcon.svg";
import searchIcon from "./assests/darkEyeIcons/searchIcon.svg";
import shieldFlashIcon from "./assests/darkEyeIcons/shieldFlashIcon.svg";
import Navbar from "../components/Navbar";
import Testimonies from "../components/Testimonies";
import Footer from "../components/Footer";

export default function DarkEye() {
  const faqData = [
    {
      question: "How often are alerts generated?",
      answer:
        "Alerts are generated based on specific conditions or triggers, which can vary depending on the system.",
    },
    {
      question: "What is the average response time?",
      answer:
        "The average response time is typically under 5 minutes, depending on the complexity of the issue.",
    },
    {
      question: "Can I customize the alerts?",
      answer:
        "Yes, you can customize alerts based on your preferences and system configurations.",
    },
    {
      question: "What platforms are supported?",
      answer:
        "Our service supports Windows, macOS, Linux, and mobile platforms like iOS and Android.",
    },
    {
      question: "Is there a trial period available?",
      answer: "Yes, we offer a 14-day free trial with access to all features.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us"); // Redirect to /contact-us
  };

  const handleLogoClick = () => {
    navigate("/"); // Redirect to /contact-us
  };
  return (
    <div className="website-layer">
      <div className="vciso-website-v-layer">
        <div className="vciso-main-wrapper">
          <Navbar activePage="vciso" />
          <div className="vciso-hero-section">
            <div className="vciso-hero-section-2">
              <div className="vciso-hero-header">
                <p className="vciso-defend-against-cyber">
                  <span className="vciso-span">Virtual CISO</span>
                </p>

                <p className="vciso-p">
                  Build DevSecOps culture with your own InHouse Security Team
                </p>
              </div>

              <div>
                <div
                  className="vciso-div-wrapper"
                  onClick={() =>
                    navigate("/contact-us", {
                      state: { activePage: "vciso" }, // Passing 'darkeye' as the value for activePage
                    })
                  }
                >
                  <div className="vciso-text-wrapper-6">
                    Setup your Team Now
                  </div>
                </div>
              </div>

              <div className="vciso-trust">
                <div className="vciso-overall-rating">
                <div className="frame" style={{display: "flex", flexDirection: "column", gap: "0px"}}>
                    <img
                      className="img"
                      alt="overallRating"
                      src={overallRating}
                    />
                    <p style={{fontSize: "1vw", color: "white", margin: "0px", fontWeight: "600"}}>We secure 1000+ Organizations</p>
                  </div>
                </div>

                <div className="vciso-frame-2">
                  <div className="image-slider">
                    <img className="img-2" alt="Img" src={asus} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={amazon} />
                    <img className="img-2" alt="Img" src={redbull} />
                    <img className="img-2" alt="Img" src={dell} />
                    <img className="img-2" alt="Img" src={apple} />
                    <img className="img-2" alt="Img" src={dominos} />
                    <img className="img-2" alt="Img" src={flipkart} />
                    <img className="img-2" alt="Img" src={microsoft} />
                    <img className="img-2" alt="Img" src={atlassian} />
                    <img className="img-2" alt="Img" src={figma} />
                    <img className="img-2" alt="Img" src={jira} />
                    <img className="img-2" alt="Img" src={licious} />
                    <img className="img-2" alt="Img" src={facebook} />
                    <img className="img-2" alt="Img" src={mastercard} />
                    <img className="img-2" alt="Img" src={ncrb} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={ncipc} />
                    <img className="img-2" alt="Img" src={yahoo} />
                    <img className="img-2" alt="Img" src={zomato} />
                    <img className="img-2" alt="Img" src={paytm} />
                    <img className="img-2" alt="Img" src={sony} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            src={watcherImg}
            style={{ marginTop: "-5vw", position: "relative", width: "55vw" }}
          />
          <div className="vciso-services-section2">
            <div className="vciso-div-2">
              <button
                className="vciso-primary-btn-instance2"
                divclassName="vciso-design-component-instance-node"
                property1="tag"
              >
                Apni Sec / Virtual CISO
              </button>
              <div className="vciso-section1-text">
                empowers organizations with In-depth security oversight,
                including regular VAPT assessments and tailored solutions to
                continuously monitor assets. We champion a Shift Left culture,
                integrating security into the development lifecycle early, and
                conduct on-site firewall audits for robust infrastructure
                defense. Additionally, we execute social engineering campaigns,
                manage bug bounty programs, and ensure your organization stays
                ahead of evolving cyber threats.
              </div>
            </div>
          </div>

          <div className="vciso-report-section2">
            <div className="vciso-section2-heading">
              Lack of Cybersecurity Leadership and Roadmap
            </div>
            <div className="vciso-report-component131">
              <div className="vciso-section-1">
                <div className="vciso-content-1">
                  <div className="vciso-text-wrapper-131">
                    Strategic Alignment
                  </div>

                  <p className="vciso-comprehensive131">
                    With Cyber Threats becoming more Sophisticated, organization
                    struggles to keep up with the best practices and emerging
                    threats
                  </p>
                </div>
              </div>

              <div className="vciso-section-2">
                <div className="vciso-content-1">
                  <div className="vciso-text-wrapper-131">
                    Untracked services
                  </div>

                  <p className="vciso-comprehensive131">
                    Legacy applications and services being exposed to new CVEs
                    or 0days and exploited in the Wild
                  </p>
                </div>
              </div>
              <div className="vciso-section-3">
                <div className="vciso-content-1">
                  <div className="vciso-text-wrapper-131">Weak Links </div>

                  <p className="vciso-comprehensive131">
                    High Risk teams susceptible to social engineering attacks,
                    CEO Fraud and DeepFakes
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="vciso-report-section3">
            <div className="vciso-section3-heading">
              Comprehensive Protection at Your Fingertips
            </div>
            <div className="vciso-report-component131">
              <div className="vciso-section-1">
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={searchIcon}
                  />
                  <div className="vciso-text-wrapper-132">
                    Assets Monitoring
                  </div>
                  <p className="vciso-comprehensive132">
                    Integration of our in-house solution Watcher to keep an eye
                    on your assets 24/7 with fine grained alerts for
                    configuration changes
                  </p>
                </div>
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={shieldFlashIcon}
                  />
                  <div className="vciso-text-wrapper-132">Dark Eye Watcher</div>
                  <p className="vciso-comprehensive132">
                    Scrape the deep web for sensitive data leaks credentials,
                    documents, cookies related to the organization
                  </p>
                </div>
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={shieldFlashIcon}
                  />
                  <div className="vciso-text-wrapper-132">Threat Modelling</div>
                  <p className="vciso-comprehensive132">
                    Potential Vulnerabilities and attack vectors by analyzing
                    and tracking systems, applications and processes.
                  </p>
                </div>
              </div>

              <div className="vciso-section-2">
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={notifyIcon}
                  />
                  <div className="vciso-text-wrapper-132">continuous VAPT</div>
                  <p className="vciso-comprehensive132">
                    Regular PR reviews, manual source code reviews and dynamic
                    testing of weekly feature releases and code changes going
                    live
                  </p>
                </div>
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={fileChartIcon}
                  />
                  <div className="vciso-text-wrapper-132">
                    Bug Bounty Program
                  </div>
                  <p className="vciso-comprehensive132">
                    End to end manage bbp as an an add on layer of Security with
                    invite to elite Security Researchers disclosing security
                    vulnerabilities
                  </p>
                </div>
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={fileChartIcon}
                  />
                  <div className="vciso-text-wrapper-132">SEC-101 sessions</div>
                  <p className="vciso-comprehensive132">
                    Regular security 101 sessions for awareness and quizzes to
                    track score for each individual
                  </p>
                </div>
              </div>
              <div className="vciso-section-3">
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={nodeIcon}
                  />
                  <div className="vciso-text-wrapper-132">SAST Pipelines</div>
                  <p className="vciso-comprehensive132">
                    Implement static code analysis checks in your deployment
                    pipelines to eliminate vulnerabilities at the initial phases
                  </p>
                </div>
                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={dbIcon}
                  />
                  <div className="vciso-text-wrapper-132">
                    Red Team Asssessment
                  </div>
                  <p className="vciso-comprehensive132">
                    Regular on-site network, firewall audits , social
                    engineering campaigns and awareness sessions org-wide
                  </p>
                </div>

                <div className="vciso-content-1">
                  <img
                    className="vciso-socialIcon132"
                    alt="Twitter"
                    src={dbIcon}
                  />
                  <div className="vciso-text-wrapper-132">
                    Third Party Assessment
                  </div>
                  <p className="vciso-comprehensive132">
                    Track and Security assessment of third party vendors and
                    dashboards depending on the data shared with them.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="vciso-stats-section-1">
            <div className="vciso-div-3">
              <div className="vciso-frame-15">
                <div className="vciso-text-wrapper-141">900+</div>

                <div className="vciso-text-wrapper-301">Pipelines Secured</div>
              </div>

              <div className="vciso-frame-15">
                <div className="vciso-element">15K+</div>
                <div className="vciso-text-wrapper-301">Assets Monitored</div>
              </div>
              <div className="vciso-frame-15">
                <div className="vciso-text-wrapper-141">1k+</div>

                <div className="vciso-text-wrapper-301">BBP Reports Closed</div>
              </div>

              <div className="vciso-frame-15">
                <div className="vciso-text-wrapper-141">300Bn+</div>

                <div className="vciso-text-wrapper-301">Data Scraped</div>
              </div>
            </div>
          </div>

          <div className="vciso-how-we-do-it-section">
            <div className="vciso-title-2">
              <div className="vciso-overlap-group-6">
                <img className="vciso-line-5" alt="Line" src={line74} />

                <div className="vciso-how-we-do-it">How We Do It</div>
              </div>
            </div>

            <div className="vciso-w-rapper-wrapper">
              <div className="vciso-w-rapper">
                <div className="vciso-overlap-6">
                  <div className="vciso-frame-16">
                    <div className="vciso-content-2">
                      <div className="vciso-img-wrapper">
                        <img
                          className="vciso-watcher"
                          alt="Frame"
                          src={searchIcon}
                        />
                      </div>

                      <p className="vciso-text-wrapper-32">
                        Define unique keywords, domain list, Third Party
                        Dashboards of organization
                      </p>
                    </div>

                    <div className="vciso-content-3">
                      <div className="vciso-img-wrapper">
                        <img
                          className="vciso-watcher"
                          alt="Watcher"
                          src={dbIcon}
                        />
                      </div>

                      <p className="vciso-text-wrapper-32">
                        Advanced AI filters identify and prioritize working
                        Credentials and Leaks directly impacting the
                        organization
                      </p>
                    </div>

                    <div className="vciso-content-4">
                      <div className="vciso-img-wrapper">
                        <img
                          className="vciso-watcher"
                          alt="Report"
                          src={nodeIcon}
                        />
                      </div>

                      <p className="vciso-text-wrapper-32">
                        Persistent Monitoring and 24/7 Support for Detailed leak
                        analysis
                      </p>
                    </div>
                  </div>

                  <div className="vciso-frame-17">
                    <div className="vciso-content-5">
                      <div className="vciso-img-wrapper">
                        <img
                          className="vciso-watcher"
                          alt="Penetration testing"
                          src={shieldFlashIcon}
                        />
                      </div>

                      <p className="vciso-text-wrapper-32">
                        Continuous scanning of dark web forums, marketplaces,
                        and chat platforms.
                      </p>
                    </div>

                    <div className="vciso-content-6">
                      <div className="vciso-img-wrapper">
                        <img
                          className="vciso-watcher"
                          alt="Blockchain"
                          src={notifyIcon}
                        />
                      </div>

                      <p className="vciso-text-wrapper-32">
                        Instant alerts and Monthly reports sent to the team.
                      </p>
                    </div>
                  </div>

                  <div className="vciso-group-13">
                    <div className="vciso-overlap-6">
                      <div className="vciso-lines">
                        <div className="vciso-overlap-group-7">
                          <img
                            className="vciso-line-6"
                            alt="Line"
                            src={line79}
                          />

                          <img
                            className="vciso-line-7"
                            alt="Line"
                            src={line81}
                          />

                          <img
                            className="vciso-line-8"
                            alt="Line"
                            src={line83}
                          />
                        </div>

                        <img className="vciso-line-9" alt="Line" src={line80} />

                        <img
                          className="vciso-line-10"
                          alt="Line"
                          src={line82}
                        />

                        <img
                          className="vciso-line-11"
                          alt="Line"
                          src={line84}
                        />
                      </div>

                      <div className="vciso-point">
                        <div className="vciso-timelineDot" />

                        <div className="vciso-timelineDot" />

                        <div className="vciso-timelineDot" />

                        <div className="vciso-timelineDot" />

                        <div className="vciso-timelineDot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vciso-about-section">
            <div className="vciso-about-component">
              <div className="vciso-col">
                <img
                  className="vciso-report-img"
                  alt="Report img"
                  src={reportImgSingle}
                />
              </div>

              <div className="vciso-col-2">
                <div className="vciso-div-4">
                  <div className="vciso-text-wrapper-36">Sample Report</div>
                  <div className="vciso-text-wrapper-37">
                    Comprehensive evaluations to identify vulnerabilities and
                    provide actionable insights for risk mitigation.
                  </div>
                  <div className="vciso-div-wrapper">
                    <div
                      className="cloudsecurity-text-wrapper-6"
                      onClick={() =>
                        window.open(
                          "https://assets.apnisec.com/public/Sample+Reports.zip",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Download Sample Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vciso-testimonial-section">
            <div className="vciso-testimonial-wrap">
              <Testimonies activePage="vciso" />
            </div>
          </div>

          <div className="vciso-team-section">
            <div className="vciso-text-wrapper-39">
              Frequently Asked Questions
            </div>

            <div
              style={{
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                width: "65vw",
              }}
            >
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #00ffb2",
                    borderRadius: "5px",
                    marginBottom: "1vw",
                    backgroundColor: "#f5fffc",
                    padding: "1vw",
                  }}
                >
                  <div
                    onClick={() => toggleFAQ(index)}
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1vw",
                      cursor: "pointer",
                      fontSize: "1.25vw",
                    }}
                  >
                    {faq.question}
                    <span
                      style={{
                        transform:
                          activeIndex === index
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      ▼
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        padding: "2vw",
                        fontSize: "1vw",
                        color: "#333",
                        borderTop: "1px solid #c9f9c9",
                      }}
                    >
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="vciso-CTA-section">
            {/* <div className="vciso-background" /> */}
            <div className="vciso-networkLinesWrapper">
              {" "}
              <img
                className="vciso-networkLines"
                alt="Vector"
                src={networkLines}
              />
            </div>

            <div className="vciso-div-2">
              <div className="vciso-text-wrapper-40">Protect Your Data Now</div>
            </div>
            <div className="vciso-div-wrapper">
              <div
                className="vciso-text-wrapper-6"
                onClick={() =>
                  navigate("/contact-us", {
                    state: { activePage: "vciso" }, // Passing 'darkeye' as the value for activePage
                  })
                }
              >
                Get Quote
              </div>
            </div>
            <div className="vciso-networkLinesWrapper1">
              {" "}
              <img
                className="vciso-networkLines1"
                alt="Vector"
                src={networkLines}
              />
            </div>
          </div>
          <Footer />
        
        </div>
      </div>
    </div>
  );
}
